import React from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.scss';
import { ModalClose } from './modal-close/ModalClose';
import classNames from 'classnames';

export interface INewModal {
    onClose?(): void;
    children?: React.ReactNode;
    isClosable?: boolean;
    classes?: string;
}

export const Modal: React.FC<INewModal> = ({ children, onClose, isClosable, classes }) => {
    return ReactDOM.createPortal(
        <div data-testid={'modalElement'} className={styles.modal}>
            <div className={classNames(styles.modalWrapper, classes)}>
                {children}
                {isClosable && <ModalClose onClose={onClose} />}
            </div>
            <div
                data-testid="modal-backdrop"
                className={styles.modalBackdrop}
                onClick={isClosable ? onClose : undefined}
            />
        </div>,
        document.body
    );
};
