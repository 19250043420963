// DO NOT MOVE THIS FILE. Used by pods to serve app.

// NEW LANGUAGE
export enum Locale {
    pl_PL = 'pl_PL',
    sk_SK = 'sk_SK',
    cs_CZ = 'cs_CZ',
    en_GB = 'en_GB',
}

// NEW LANGUAGE
export enum Language {
    pl_PL = 'pl',
    sk_SK = 'sk',
    cs_CZ = 'cs',
    en_GB = 'en',
}

// NEW LANGUAGE
export const LocaleByLanguage = {
    pl: Locale.pl_PL,
    sk: Locale.sk_SK,
    cs: Locale.cs_CZ,
    en: Locale.en_GB,
};

// NEW LANGUAGE
export const MarketSymbol: Record<string, string> = {
    pl: 'PL',
    sk: 'SK',
    cs: 'CZ',
    en: 'EU',
};
