import React from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';
import Link from 'next/link';

export enum ButtonType {
    BUTTON = 'button',
    LINK = 'link',
    PLAIN_BUTTON = 'plain',
}

export enum ButtonColor {
    BLACK = 'black',
    WHITE = 'white',
    TRANSPARENT_WHITE = 'transparent-white',
    TRANSPARENT_BLACK = 'transparent-black',
}

interface IButtonStrategy {
    [buttonType: string]: () => JSX.Element;
}

interface IButton {
    buttonType: ButtonType;
    buttonColor?: ButtonColor;
    children?: React.ReactNode;
    fullWidth?: boolean;
    className?: string;
    classes?: string;
    title?: string;
    url?: string;
    testId?: string;
    onClick?: (event: any) => void;
}

export const Button: React.FC<IButton> = ({
    className,
    buttonType,
    buttonColor,
    classes,
    title,
    children,
    fullWidth,
    url,
    testId,
    onClick,
}) => {
    const btnStyles = {
        [styles.btnBlack]: buttonColor === ButtonColor.BLACK,
        [styles.btnWhite]: buttonColor === ButtonColor.WHITE,
        [styles.btnTransparentWhite]: buttonColor === ButtonColor.TRANSPARENT_WHITE,
        [styles.btnTransparentBlack]: buttonColor === ButtonColor.TRANSPARENT_BLACK,
        [styles.btnFullWidth]: fullWidth,
        [className || '']: className,
    };

    const buttonStrategy: IButtonStrategy = {
        [ButtonType.PLAIN_BUTTON]: () => (
            <button data-testid={testId} className={classNames(classes)} onClick={onClick}>
                {children}
            </button>
        ),
        [ButtonType.BUTTON]: () => (
            <button
                data-testid={testId}
                className={classNames(styles.btn, classes, btnStyles)}
                title={title}
                onClick={onClick}>
                {children}
            </button>
        ),
        [ButtonType.LINK]: () => (
            <Link
                data-testid={testId}
                role="button"
                href={url || ''}
                className={classNames(styles.btn, classes, btnStyles)}>
                {children}
            </Link>
        ),
    };

    if (buttonStrategy[buttonType]) {
        return buttonStrategy[buttonType]();
    }

    return (
        <button data-testid={testId} className={styles.btn}>
            {children}
        </button>
    );
};
