import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next, useTranslation as useTranslationOrg } from 'react-i18next';
import { i18nextConfig, supportedLanguages } from './next-i18next.config';
import { useEffect, useState } from 'react';
import { i18nextDetector, lngDetector, lngDetectorServer } from '@app/i18n/lngDetection/lngDetector';
import { promises } from 'fs';

const runsOnServerSide = typeof window === 'undefined';

const config = {
    ...i18nextConfig(undefined),
    preload: runsOnServerSide ? supportedLanguages : [],
    backend: {
        loadPath: '/locales/{{lng}}.json',
    },
};

export const emptyI18nModule = {
    type: 'emptymodule',
};

const loadResourcesIfOnServerFromFile = () => {
    return resourcesToBackend(async (lang: string) => {
        if (runsOnServerSide) {
            try {
                const { promises } = require('fs');
                const translations: string = await promises.readFile(
                    `${process.cwd()}/public/locales/${lang}.json`,
                    'utf-8'
                );
                return JSON.parse(translations);
            } catch (e: any) {}
        }
    });
};

i18next
    .use(HttpBackend)
    .use(i18nextDetector as any)
    .use(runsOnServerSide ? loadResourcesIfOnServerFromFile() : (emptyI18nModule as any))
    .use(initReactI18next)
    .init(config);

export function useTranslation() {
    const orgUseTranslations = useTranslationOrg();
    const { i18n } = orgUseTranslations;
    const lang = lngDetector();

    if (runsOnServerSide) {
        const lang = lngDetectorServer();
        i18n.changeLanguage(lang);
    } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            if (activeLng === i18n.resolvedLanguage) {
                return;
            }
            setActiveLng(i18n.resolvedLanguage);
        }, [activeLng, i18n.resolvedLanguage]);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            i18n.changeLanguage(lang);
        }, [lang, i18n]);
    }
    return orgUseTranslations;
}
