import React from 'react';
import classNames from 'classnames';
import { ITab } from '../DevToolbar.interfaces';
import styles from '../DevToolbar.module.scss';

export const Tab = (props: ITab) => {
    return (
        <div
            className={classNames(styles.tab, {
                [styles.selectedTab]: props.isOpen,
            })}
            onClick={props.onClick}>
            {props.name}
        </div>
    );
};
