import { Language } from '@/services/language.service';

// NEW LANGUAGE
export const supportedLanguages = [Language.pl_PL, Language.sk_SK, Language.cs_CZ, Language.en_GB];

export const i18nextConfig = (lng?: string) => {
    return {
        lng: lng,
        fallbackLng: lng,
        locales: supportedLanguages,
        supportedLngs: supportedLanguages,
    };
};
