import React from 'react';
import Cookies from 'js-cookie';

export const CONSENT_COOKIE = 'cookie-consent';
export const COOKIE_EXPIRATION_DAYS = 60;

export const useCookiesPopup = () => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    React.useEffect(() => {
        if (!Cookies.get(CONSENT_COOKIE)) {
            setIsModalOpen(true);
        }
    }, []);

    const handleCookieAcceptButtonClick = () => {
        Cookies.set(CONSENT_COOKIE, '1', { expires: COOKIE_EXPIRATION_DAYS });

        setIsModalOpen(false);
    };

    return { handleCookieAcceptButtonClick, isModalOpen };
};
