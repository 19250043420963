import React from 'react';
import { Accordion, AccordionIcon } from '@/components/accordion/Accordion';
import { IHttpCall } from '../DevToolbar.interfaces';
import styles from '../DevToolbar.module.scss';

export const NetworkTab: React.FC<{ calls: IHttpCall[] }> = (props) => {
    if (!props.calls.length) {
        return <div>No network calls</div>;
    }

    return (
        <div>
            {props.calls.map((call, index) => (
                <Accordion key={index} title={`${call.status} - ${call.url}`} iconType={AccordionIcon.CUSTOM}>
                    {call.body ? (
                        <div className={styles.tabContent}>
                            <div>Response body:</div>
                            <pre>{JSON.stringify(call.body, null, 2)}</pre>
                        </div>
                    ) : (
                        <div>Nobody :(</div>
                    )}
                </Accordion>
            ))}
        </div>
    );
};
