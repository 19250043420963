import { IApiRequest, IApiResponse } from '@/hooks/api/useApi/useApi.interfaces';

export const isDevToolbarEnabled = process.env.NEXT_PUBLIC_DEV_TOOL_BAR === 'true';

export interface IHttpCall {
    url: string;
    status: number;
    requestBody?: object;
    body?: object;
}

export interface IApiCalls {
    [key: string]: IHttpCall[];
}

export const addHttpCalls = (id: string, request: IApiRequest, response: IApiResponse<object>, apiCalls: IApiCalls) => {
    const { status, ...body } = response;

    const call = {
        url: request.url,
        requestBody: request.body,
        status: status,
        body: body,
    };

    if (!apiCalls[id]) {
        apiCalls[id] = [];
    }

    apiCalls[id].push(call);
};
