import { IApiRequest, IApiResponse } from '@/hooks/api/useApi/useApi.interfaces';
import { IApiCalls, IHttpCall, addHttpCalls } from './fetchLogger.service';

const apiCalls: IApiCalls = {};

export const addClientHttpCalls = async (request: IApiRequest, response: IApiResponse<object>) => {
    await addHttpCalls('client', request, response, apiCalls);
};

export const getClientHttpCalls = (): IHttpCall[] => {
    return apiCalls.client || [];
};
