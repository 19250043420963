import { headers } from 'next/headers';
const { domainLocaleMap } = require('../../../next-i18next.config.helper');

export const lngDetector = (): string | undefined => {
    if (typeof window !== 'undefined') {
        return domainLocaleMap(window.location.hostname);
    }

    return undefined;
};

export const lngDetectorServer = (): string => {
    const { headers } = require('next/headers');
    const host = headers().get('host');
    const hostWithoutPort = host.endsWith(':3000') ? host.slice(0, -5) : host;
    return domainLocaleMap(hostWithoutPort as string);
};

export const i18nextDetector = {
    type: 'languageDetector',
    detect: lngDetector,
};
