export interface ITab {
    name: string;
    isOpen?: boolean;
    onClick: () => void;
}

export enum TABS {
    NETWORK,
}

export interface IHttpCall {
    url: string;
    status: number;
    statusText?: string;
    body?: object;
}

export interface IDevToolbarClient {
    serverHttpCalls: IHttpCall[];
}
