'use client';

import React from 'react';
import { useTranslation } from '@app/i18n/useTranslationClient';
import { Button, ButtonColor, ButtonType } from '../button/Button';
import { Modal } from '../modal/Modal';
import styles from './CookiesConsentPopup.module.scss';
import { useCookiesPopup } from './useCookiesPopup';

export const CookiesConsentPopup = () => {
    const { t } = useTranslation();
    const { handleCookieAcceptButtonClick, isModalOpen } = useCookiesPopup();

    if (!isModalOpen) {
        return null;
    }

    return (
        <Modal classes={styles.cookiesPopupContainer}>
            <h4 className={styles.cookiesPopupTitle}>{t('translations.cookies.title')}</h4>
            <p className={styles.cookiesPopupDescription}>{t('translations.cookies.description')}</p>
            <Button
                testId={'cookiesAcceptBtn'}
                classes={styles.cookiesPopupAcceptButton}
                buttonType={ButtonType.BUTTON}
                buttonColor={ButtonColor.BLACK}
                onClick={handleCookieAcceptButtonClick}>
                {t('translations.cookies.acceptButton')}
            </Button>
        </Modal>
    );
};
