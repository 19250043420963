'use client';

import React from 'react';
import styles from './DevToolbar.module.scss';
import { getClientHttpCalls } from '@/services/fetchLogger/fetchClientLogger.service';
import { IHttpCall, TABS } from './DevToolbar.interfaces';
import { NetworkTab } from './networkTab/DevToolbarNetworkTab';
import { Tab } from './tab/DevToolbarTab';

export const DevToolbarClient: React.FC<{ serverHttpCalls: IHttpCall[] }> = (props) => {
    const [httpCalls, setHttpCalls] = React.useState<IHttpCall[]>(props.serverHttpCalls);
    const [openedTab, setOpened] = React.useState<TABS | null>(null);

    const toggleOpen = (tab: TABS) => {
        if (openedTab === tab) {
            setOpened(null);
            return;
        }
        setHttpCalls([...getClientHttpCalls(), ...props.serverHttpCalls]);
        setOpened(tab);
    };

    const isOpen = (tab: TABS) => openedTab === tab;

    return (
        <div className={styles.toolbar}>
            <div className={styles.tabsContents}>{openedTab === TABS.NETWORK && <NetworkTab calls={httpCalls} />}</div>
            <div className={styles.tabs}>
                <Tab name="Network" isOpen={isOpen(TABS.NETWORK)} onClick={() => toggleOpen(TABS.NETWORK)} />
            </div>
        </div>
    );
};
