'use client';

import React from 'react';
import styles from './Accordion.module.scss';
import classNames from 'classnames';

export enum AccordionIcon {
    CHEVRON = 'chevron',
    PLUS = 'plus',
    MINUS = 'minus',
    ARROW_WHITE = 'arrow_white',
    ARROW_BLACK = 'arrow_black',
    CUSTOM = 'custom',
}

export enum BorderColor {
    BLACK = 'black',
    WHITE = 'white',
}

interface IAccordion {
    children: React.ReactNode;
    iconType: AccordionIcon;
    title: string;
    customIcon?: React.ReactNode;
    customIconOpen?: React.ReactNode;
    borderColor?: BorderColor;
    accordionMaxWidth?: string;
    classes?: IAccordionClasses;
    defaultOpen?: boolean;
}

interface IAccordionClasses {
    wrapper?: string;
    title?: string;
    titleWrapper?: string;
    content?: string;
    icon?: string;
    openedAccordion?: string;
}

export const Accordion: React.FC<IAccordion> = ({
    children,
    classes,
    iconType,
    title,
    accordionMaxWidth,
    customIcon,
    customIconOpen,
    borderColor,
    defaultOpen,
}) => {
    const [isOpen, setIsOpen] = React.useState(defaultOpen);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const isArrowIcon = (typeOfIcon: string) =>
        typeOfIcon === AccordionIcon.ARROW_WHITE || typeOfIcon === AccordionIcon.ARROW_BLACK;
    const isPlusMinusIcon = (typeOfIcon: string) =>
        typeOfIcon === AccordionIcon.PLUS || typeOfIcon === AccordionIcon.MINUS;

    const renderIcon = (typeOfIcon: AccordionIcon, custom?: React.ReactNode, customOpen?: React.ReactNode) => {
        if (typeOfIcon === AccordionIcon.CHEVRON) {
            return (
                <span
                    data-testid={'accordionIcon'}
                    className={classNames(styles.accordionChevron, classes?.icon, {
                        [styles.accordionChevronOpen]: isOpen,
                    })}
                />
            );
        }

        if (isPlusMinusIcon(typeOfIcon)) {
            return (
                <span
                    data-testid={'accordionIcon'}
                    className={classNames(styles.accordionPlus, {
                        [styles.accordionMinus]: isOpen,
                    })}
                />
            );
        }

        if (isArrowIcon(typeOfIcon)) {
            return (
                <span
                    data-testid={'accordionIcon'}
                    className={classNames(styles.accordionArrow, {
                        [styles.accordionArrowWhite]: typeOfIcon === AccordionIcon.ARROW_WHITE,
                        [styles.accordionArrowBlack]: typeOfIcon === AccordionIcon.ARROW_BLACK,
                    })}
                />
            );
        }

        if (typeOfIcon === AccordionIcon.CUSTOM && custom) {
            return isOpen ? customOpen : custom;
        }

        return;
    };

    return (
        <div
            data-testid={'accordionContent'}
            className={classNames(styles.accordion, classes?.wrapper, {
                [styles.accordionOpen]: isOpen,
                [styles.accordionArrowOpened]: isArrowIcon(iconType) && isOpen,
                [styles.accordionBordered]: borderColor,
                [styles.accordionBorderedWhite]: borderColor === BorderColor.WHITE,
                [styles.accordionBorderedWhiteOpen]: borderColor === BorderColor.WHITE && isOpen,
                [styles.accordionBorderedBlack]: borderColor === BorderColor.BLACK,
                [styles.accordionBorderedBlackOpen]: borderColor === BorderColor.BLACK && isOpen,
            })}
            style={{ maxWidth: accordionMaxWidth }}>
            <div
                data-testid={'accordionToggler'}
                className={classNames(styles.accordionTitleWrapper, classes?.titleWrapper, {
                    [classes?.openedAccordion || '']: isOpen && classes,
                })}
                onClick={handleToggle}>
                <span className={classes?.title}>{title}</span>
                {renderIcon(iconType, customIcon, customIconOpen)}
            </div>
            {children && (
                <div
                    data-testid={'accordionChildren'}
                    className={classNames(styles.accordionContent, classes?.content)}>
                    {children}
                </div>
            )}
        </div>
    );
};
