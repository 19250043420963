// NEW LANGUAGE
exports.domainsPl = {
    localhost: 'localhost',
    local: 'bank-med-pl.local',
    beta: 'beta-med-pl-art.ans-wear.net',
    prod: 'art.wearmedicine.com',
};

exports.domainsSk = {
    local: 'bank-med-sk.local',
    beta: 'beta-med-sk-art.ans-wear.net',
    prod: 'art.wearmedicine.sk',
};

exports.domainsCz = {
    local: 'bank-med-cz.local',
    beta: 'beta-med-cz-art.ans-wear.net',
    prod: 'art.wearmedicine.cz',
};

exports.domainsEu = {
    local: 'bank-med-eu.local',
    beta: 'beta-med-eu-art.ans-wear.net',
    prod: 'art.wearmedicine.eu',
};

// NEW LANGUAGE
const Languages = {
    PL: 'pl',
    SK: 'sk',
    CZ: 'cs',
    EN: 'en',
};

// NEW LANGUAGE
exports.domainLocaleMap = (domain) => {
    const lng = {
        [exports.domainsPl.localhost]: Languages.PL,
        [exports.domainsPl.local]: Languages.PL,
        [exports.domainsPl.beta]: Languages.PL,
        [exports.domainsPl.prod]: Languages.PL,

        [exports.domainsSk.local]: Languages.SK,
        [exports.domainsSk.beta]: Languages.SK,
        [exports.domainsSk.prod]: Languages.SK,

        [exports.domainsCz.local]: Languages.CZ,
        [exports.domainsCz.beta]: Languages.CZ,
        [exports.domainsCz.prod]: Languages.CZ,

        [exports.domainsEu.local]: Languages.EN,
        [exports.domainsEu.beta]: Languages.EN,
        [exports.domainsEu.prod]: Languages.EN,
    }[domain];

    return lng || Languages.PL;
};
